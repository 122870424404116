<template>
  <div class="hello">
    <q-card class="my-card">
      <q-card-section>
        <q-item-label class="text-h3">
          Welcome to your messaging Inbox
        </q-item-label>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-list bordered>
          <q-item
                  v-for="(conversation,index) in messages" :key="index" class="q-my-sm" clickable v-ripple
                  :href="'/message/'+conversation.user_id">
            <q-item-section avatar>
              <q-avatar color="primary" text-color="white">
                {{ this.getFirstLetter(conversation.user_name) }} {{ this.getFirstLetter(conversation.user_surname) }}
              </q-avatar>
            </q-item-section>
              <q-item-section>
                <q-item-label>{{ conversation.user_name }} {{ conversation.user_surname }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <span>{{ conversation.unread_count }}</span>
                <q-icon v-if="(parseInt(conversation.unread_count))===0" name="chat_bubble" color="gray"></q-icon>
                <q-icon v-if="(parseInt(conversation.unread_count))>0" name="chat_bubble" color="green"></q-icon>
              </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import axios from "axios";
import { Notify } from 'quasar'
import NoSleep from 'nosleep.js';

export default {
  name: 'PageInbox',
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
  },
  computed:{
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  data(){
    return {
      messages : Object,
      draw_list : 0,
    }
  },
  mounted(){
     this.setupPage();
     this.getMessages();
  },
  methods: {
    getFirstLetter(string){
        return string.charAt(0);
    },
    setupPage(){
      const router = useRouter();
      let token = this.$store.getters.token;
      let user_id = JSON.parse( localStorage.getItem('user_id') );
      if( !token ){
        router.push({ name: "login"})
      }else{
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.get(this.$store.getters.url+'/api/cases/tasks?task_owner='+user_id+'&task_status='+5)
            .then((res) => {
              res.data.data.forEach(function(task) {
                if(task.status_id==="5"){
                  Notify.create({
                    color: 'orange-5',
                    textColor: 'white',
                    icon: 'warning',
                    message: 'You are still enroute to task: '+task.id,
                    timeout: 10000,
                  })
                  let url = '/execute-task/'+task.id;
                  router.push({ path: url})
                }
                if(task.status_id==="8"){
                  Notify.create({
                    color: 'orange-5',
                    textColor: 'white',
                    icon: 'warning',
                    message: 'You are still busy with checklist for task: '+task.id,
                    timeout: 10000,
                  })
                  let url = '/execute-task/'+task.id;
                  router.push({ path: url})
                }
              });
            })
            .catch(() => {
            }).finally(() => {

        });
      }
    },
    getMessages() {
      axios.get(this.$store.getters.url+'/api/api-instant-messages')
          .then((res) => {
            this.messages = res.data;
            console.log(this.messages);
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    deleteMessage(index) {
      let url = this.$store.getters.url+'/api/cases/messages/' + index;
      axios.delete(url)
          .then(() => {
            this.getMessages();
          })
          .catch(() => {
          }).finally(() => {
              this.getMessages();
          });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.icon-with-number {
  position: relative;
  display: inline-block;
  font-size: 24px;
}

.icon-with-number .number {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
</style>

