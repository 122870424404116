<template>
  <div class="hello">
    <q-card class="my-card">
      <q-card-section>
        <q-item-label class="text-h6">
           Conversations for case {{this.returned_cases.id}}
        </q-item-label>
        <q-btn class="bg-blue-5 text-white"  :href="'/compose-message/'+this.returned_cases.id">
          Create Message
        </q-btn>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-list bordered>
          <q-item
              v-for="(conversation,index) in messages" :key="index" class="q-my-sm" clickable v-ripple
              :href="'/message/'+conversation.user_id+'/'+this.returned_cases.id">
            <q-item-section avatar>
              <q-avatar color="primary" text-color="white">
                {{ this.getFirstLetter(conversation.user_name) }} {{ this.getFirstLetter(conversation.user_surname) }}
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ conversation.user_name }} {{ conversation.user_surname }}</q-item-label>
            </q-item-section>
            <q-item-section side>
              <span>{{ conversation.unread_count }}</span>
              <q-icon v-if="(parseInt(conversation.unread_count))===0" name="chat_bubble" color="gray"></q-icon>
              <q-icon v-if="(parseInt(conversation.unread_count))>0" name="chat_bubble" color="green"></q-icon>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </div>

</template>

<script>
import { useRouter } from 'vue-router'
import axios from "axios";
import NoSleep from 'nosleep.js';

export default {
  name: 'CaseMessages',
  props : ['returned_cases'],
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
  },
  data(){
    return {
      messages : Object,
      draw_list : 0,
    }
  },
  computed:{
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  mounted(){
    this.getMessages();
  },
  methods: {
    getFirstLetter(string){
      return string.charAt(0);
    },
    getMessages() {
      const router = useRouter();
      let token = this.$store.getters.token;
      if( !token ){
        router.push({ name: "login"})
      }
      axios.get(this.$store.getters.url+'/api/api-instant-messages?docket_id='+this.returned_cases.id)
          .then((res) => {
            this.messages = res.data;
            console.log(this.messages);
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    deleteMessage(index) {
      let url = this.$store.getters.url+'/api/cases/messages/' + index;
      axios.delete(url)
          .then(() => {
            this.getMessages();
          })
          .catch(() => {
          }).finally(() => {
        this.getMessages();
      });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

