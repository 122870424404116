<template>
  <div class="scrollTarget">
    <q-card class="my-card">
      <q-card-section class="bg-grey-3">
        <q-item-label class="text-left text-h6">
          <span v-if="this.other_user">
            {{ this.other_user.name }} {{ this.other_user.surname }}
            <span>
              in regards to {{this.docket_description}} : {{ this.docket_id }}
            </span>
          </span>
        </q-item-label>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-list v-for="message in messages" :key="message.id" class="q-my-sm" bordered v-ripple separator aria-colcount="1">
          <q-item v-ripple>
            <!-- User Name -->
            <q-item-section>
              <q-item-label overline>
                {{ message.sender.name }} {{ message.sender.surname }}
              </q-item-label>
            </q-item-section>
            <!-- Created At and Read Status Icons in the same row -->
            <q-item-section>
              <q-item-label caption>{{ message.created_at }}</q-item-label>
            </q-item-section>

          </q-item>
          <q-item>
            <!-- Message Content -->
            <q-item-section class="q-pt-sm">
              <q-item-label caption>
                {{ message.text }}
                <span v-if="message.docket_id">{{ this.docket_description }} : {{ message.docket_id }}</span>
                <q-item-section side class="float-right">
                  <div class="flex items-center">
                    <q-icon v-if="parseInt(message.from) === parseInt(user_id)" name="check" color="blue"></q-icon>
                    <q-icon v-if="parseInt(message.from) === parseInt(user_id) && parseInt(message.read) > 0" name="check" color="blue"></q-icon>
                  </div>
                </q-item-section>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
    <q-footer bordered class="bg-white text-primary" elevated >
      <q-form
          method="post" @submit="this.sendNewMessage()"
          class="q-gutter-md">
        <q-list>
          <q-item>
            <q-item-section>
              <q-input v-model="this.reply_message" label="Message" :dense="true"></q-input>
            </q-item-section>
            <q-item-section thumbnail style="margin-right: 50px">
              <q-btn type="submit" round color="secondary" icon="navigation"></q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-form>
    </q-footer>
  </div>
</template>

<script>
import {useRoute, useRouter} from 'vue-router'
import { messaging } from "@/firebase";
import axios from "axios";
import { Notify } from 'quasar'
import NoSleep from 'nosleep.js';
import { scroll } from 'quasar'
import { getToken,onMessage } from "firebase/messaging";

export default {
  name: 'PageInbox',
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
  },
  data(){
    return {
      messages : Object,
      draw_list : 0,
      parameter_id : null,
      docket_id : 0,
      other_user_id : null,
      other_user : null,
      message_receivedVar : localStorage.getItem('message_received'),
      reply_subject : null,
      reply_message : null,
      from_user : null,
      to_user: null,
      users : [],
    }
  },
  mounted() {
    this.setupPage();
    this.setupParameters();
    this.initializeFirebase();
    this.getMessages();
  },
  computed: {
    docket_description(){
      return this.$store.getters.docket_description;
    },
    messageCount() {
      return this.messages.length;
    },
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  watch:{
    messageCount: function(){
      this.scrollToNewest();
      this.markMessagesAsRead(this.messages);
    }
  },
  methods: {
    setupPage(){
      const router = useRouter();
      let token = this.$store.getters.token;
      if( !token ){
        router.push({ name: "login"})
      }else{
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
    },
    registerToken(token) {
      let axios_token = this.$store.getters.token;
      if( axios_token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + axios_token;
      }
      axios.post(
          this.$store.getters.url+"/api/api-web-notification-token",
          {
            token: token
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json"
            }
          }
      ).then(() => {
      })
    },
    initializeFirebase() {
      getToken(messaging, { vapidKey: 'BFWaFqmCXc8Wi6AoTjFYidbTFgeZvjg6BzXkKhkcogqnXR6LZhUKWV-b0D6xZqHAeguJDKcr2dwaLBfr4ZEp7MU' })
          .then((token) => {
            console.log("Token is "+token);
            this.registerToken(token)
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      onMessage(messaging, () => {
        this.playSound();
        this.getMessages();
      });
    },
    playSound () {
      let data = { soundurl : 'https://api.blackwidow.org.za/sos.mp3'}
      let audio = new Audio(data.soundurl);
      audio.play();
    },
    scrollToNewest() {
      console.log("Scrolling");
      this.$nextTick(() => {
        const targetElement = window;
        const offset = 100000; // Scroll down 200px
        const duration = 500; // Over half a second
        scroll.setVerticalScrollPosition(targetElement, offset, duration);
      })
    },
    setupParameters() {
      const route = useRoute()
      this.other_user_id = route.params.user_id;
      try{
        this.docket_id = route.params.docket_id;
      }catch (e){
        console.log(e);
        this.docket_id = null;
      }
      axios.get(this.$store.getters.url+'/api/api-users/'+this.other_user_id)
          .then((res) => {
            this.other_user = res.data;
          })
          .catch(() => {
          }).finally(() => {

      });
      localStorage.setItem( 'from_user_id', JSON.stringify(this.parameter_id) );
    },
    getNameById(userID) {
      const record = this.users.find(item => item.id === parseInt(userID));
      return record ? record.name : null;
    },
    getUsers(){
      axios.get(this.$store.getters.url+'/api/api-users')
          .then((res) => {
            this.users = res.data.data;
            this.users.forEach((user) => {
              let user_id = this.user_id;
              let from_user_id = JSON.parse( localStorage.getItem('from_user_id') );
              if(user.id===parseInt(from_user_id)){
                this.from_user = user.name;
              }
              if(user.id===user_id){
                this.to_user = user.name;
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    sendNewMessage(){
      let user_id = this.user_id;
      let message = this.reply_message;
      let file_data = new FormData();
      if(this.docket_id){
        file_data.append("docket_id", this.docket_id);
      }
      file_data.append("text",message);
      file_data.append("sender_id", user_id)
      file_data.append("receiver_id", this.other_user_id);
      let file_config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }

      // eslint-disable-next-line no-unreachable
      axios.post(this.$store.getters.url+'/api/api-instant-messages', file_data, file_config)
          .then(() => {
            Notify.create({
              progress: true,
              timeout: 1000,
              position: 'center',
              color: 'green-5',
              textColor: 'white',
              icon: 'success',
              message: 'Message sent successfully !! '
            });
            this.reply_message = '';
            this.getMessages();
            this.scrollToNewest();

          })
          .catch((error) => {
            Notify.create({
              progress: true,
              timeout: 1000,
              position: 'center',
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Failed retrieving messages !! ' + error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
    getMessages() {
      let url = this.$store.getters.url+'/api/api-instant-messages?user_id='+this.other_user_id;
      if(this.docket_id){
        url = url + '&docket_id='+this.docket_id;
      }
      axios.get(url,{
        headers:{
          'Authorization': 'Bearer ' + this.$store.getters.token,
        }
      })
        .then((res) => {
          this.messages = res.data;
          this.messages.forEach((message,index) => {
            this.messages[index].initials = message.from_user.split(" ").map((n)=>n[0]).join(".");
          });
        })
        .catch(() => {
        }).finally(() => {
          this.getUsers();
          this.scrollToNewest();
      });

    },
    markMessagesAsRead(messages){
      let user_id =this.user_id;
      messages = messages.filter(message => message.read !== '1');
      messages = messages.filter(message => parseInt(message.to) === parseInt(user_id));
      console.log("Messages to mark as read",messages.length)
      if(messages.length===0){
        console.log("No messages to mark as read");
      }else {
        if(JSON.stringify(messages)!==undefined){
          let file_data = new FormData();
          file_data.append("action", "markMessagesAsRead")
          file_data.append("messages",JSON.stringify(messages));
          file_data.append("user_id", user_id)
          let file_config = {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          }
          axios.post(this.$store.getters.url+'/api/cases/messages/', file_data, file_config)
              .then(() => {
                this.getMessages();
              })
              .catch((error) => {
                Notify.create({
                  progress: true,
                  timeout: 1000,
                  position: 'center',
                  color: 'red-5',
                  textColor: 'white',
                  icon: 'warning',
                  message: 'Failed retrieving messages !! ' + error
                })
              }).finally(() => {
            this.$store.dispatch('updateUnreadMessageCounter')
          });
        }
      }
    },
    deleteMessage(index) {
      let url = this.$store.getters.url+'/api/cases/messages/' + index;
      axios.delete(url)
          .then(() => {
            this.getMessages();
          })
          .catch(() => {
          }).finally(() => {
        this.getMessages();
      });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

